import noop from 'lodash/noop';
import storyblok from './storyblok';

function autoCache() {
	storyblok('index').subscribe(noop);
	storyblok('layouts/default').subscribe(noop);
	storyblok('layouts/partials/footer').subscribe(noop);
	storyblok('layouts/partials/global-search').subscribe(noop);
	storyblok('layouts/partials/logo').subscribe(noop);
	storyblok('layouts/partials/navigation-bar').subscribe(noop);
	storyblok('layouts/partials/side-nav-links').subscribe(noop);
	storyblok('layouts/partials/user-dropdown').subscribe(noop);
	storyblok('system/error-messages').subscribe(noop);
	storyblok('system/success-messages').subscribe(noop);

	return null;
}

export default autoCache;

import React from 'react';
import manifest from '../manifest';
import StoryblokData from './StoryblokData';

const Story = manifest.story;
function StoryblokErrors(error) {
	return (
		<StoryblokData data={{ error, errorResponse: error }}>
			<Story story={{ linktype: 'story', cached_url: '/system/error-messages' }} />
		</StoryblokData>
	);
}

export default StoryblokErrors;

import React, { createContext } from 'react';
import { Loader } from '@outsource-school/helper';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';
import autoCache from '../api/autoCache';

import getSession from '../api/session';
import StoryblokPage from './StoryblokPage';
import StoryblokData from './StoryblokData';

function getCookies() {
	return document.cookie.split('; ').reduce((o, pair) => {
		const [k, v] = pair.split('=');
		return Object.assign(o, { [k]: v });
	}, {});
}

export const SessionCtx = createContext();
function LoginGuard({ children }) {
	const { pathname } = useLocation();
	const isPublic = /^\/public/i.test(pathname);

	return (
		<Loader services={() => [getSession()]}>
			{({ results: [{ data }], reload, setResults }) => {
				const user = get(data, 'passport.user');
				let [host, slug] = window.location.hostname.match(/(.+)\.(.+)\.(.+)$/) || [];
				slug = getCookies().organizationId;
				const matchingSlug = slug === get(data, 'organization.slug');

				data.orgAllow = matchingSlug && get(data, 'organization.hasPermission');
				if (!slug || !host) data.orgAllow = true;
				if (slug === 'app') data.orgAllow = true;
				if (slug !== 'app') data.slug = slug;

				const allow = data.orgAllow && (isPublic || user);
				const isHome =
					window.location.href === 'https://tools.outsourceschool.com/index' ||
					window.location.href === 'https://tools.outsourceschool.com/';

				if (!allow && !isHome) window.sessionStorage.setItem('_redirect', window.location.href);

				// if (!data.orgAllow) {
				// 	return (
				// 		<SessionCtx.Provider value={{ data, reload, setResults }}>
				// 			<StoryblokData data={{ session: data }}>
				// 				<StoryblokPage page="system/org-request" />
				// 			</StoryblokData>
				// 		</SessionCtx.Provider>
				// 	);
				// }

				return (
					<SessionCtx.Provider value={{ data, reload, setResults }}>
						<StoryblokData data={{ session: data }}>
							{allow && children}
							{allow && autoCache()}
							{!allow && <StoryblokPage page="system/login" />}
						</StoryblokData>
					</SessionCtx.Provider>
				);
			}}
		</Loader>
	);
}

export default LoginGuard;

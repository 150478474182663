import React, { useContext } from 'react';
import get from 'lodash/get';
// import Alert from 'react-bootstrap/Alert';
// import isString from 'lodash/isString';
import { FormContext } from '@outsource-school/helper';

import StoryblokErrors from './StoryblokErrors';

function HttpError({ useForm, header, error, reload, ...props }) {
	const form = useContext(FormContext);
	// const locale = String;
	const formError = useForm && form.httpError;
	const connectionError = !get(error, 'response.status') || get(error, 'response.status') >= 500;
	if (!error && !formError) return null;
	console.log('HttpError -> error', error);

	// const { response } = formError || error;
	// const stack = get(response, 'data.error.stack') || get(error, 'stack');
	// const info = get(response, 'data.info.message') || get(error, 'message');
	// const body = get(response, 'data');

	if (connectionError && reload)
		return (
			<div class="d-grid gap-2">
				<button className="btn " onClick={reload}>
					<span>Connection Error, click here to retry</span>
					<i className="ml-3 fal fa-sync"></i>
				</button>
			</div>
		);

	return <StoryblokErrors error={formError || error} />;

	// return (
	// 	<Alert variant="warning" {...props}>
	// 		<Alert.Heading>
	// 			{header || locale('There was an issue connecting to OutsourceSchool')}
	// 		</Alert.Heading>
	// 		<hr />
	// 		<p>{locale('The full error is displayed below', { response })}</p>
	// 		<p>{isString(body) && body}</p>
	// 		{info && <p>{info}</p>}
	// 		{stack && <pre>{stack}</pre>}
	// 	</Alert>
	// );
}

export default HttpError;

import React, { useContext } from 'react';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { StoryblokDataCtx } from '../components/StoryblokData';
import template from '../utils/template';

function TextBlock({ body, as, text, textAlign, textWrap, className, ...props }) {
	const AS = as;
	const sbData = useContext(StoryblokDataCtx);
	const bs5 = String(textAlign).replace('left', 'start').replace('right', 'end');
	if (!body) return <div />;

	if (AS) return <AS as={ReactMarkdown} escapeHtml={false} source={template(body, sbData)} />;
	const source = template(body, sbData);

	return (
		<ReactMarkdown
			key={source}
			className={classnames(className, text, bs5, textWrap || 'text-break', 'rmd')}
			escapeHtml={false}
			source={source}
			transformLinkUri={(url) => url}
		/>
	);
}
export default TextBlock;

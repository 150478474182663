import React, { useContext } from 'react';
import classnames from 'classnames';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { FormGroupNameContext } from '@outsource-school/helper';
import Card from 'react-bootstrap/Card';

import { CardCtx } from './Card';
import ComponentOutlet from '../components/ComponentOutlet';
import template from '../utils/template';
import StoryblokData, { StoryblokDataCtx } from '../components/StoryblokData';

function CardContent({ body, bg, className, type, state, eventKey, text, ...rest }) {
	const sbData = useContext(StoryblokDataCtx);
	const accordionCtx = useContext(AccordionContext);
	const { accordion } = useContext(CardCtx);
	const { name } = useContext(FormGroupNameContext);
	const actKey = template(eventKey, sbData);
	const selected = accordionCtx === (actKey || name);
	const props = { ...rest, className: classnames(className, `bg-${bg} ${text}`) };
	const CardType = Card[type];

	if (type === 'Header' && accordion) {
		return (
			<CardType {...props}>
				<StoryblokData data={{ selected }}>
					<ComponentOutlet components={body} />
				</StoryblokData>
			</CardType>
		);
	}

	if (type === 'Body' && accordion) {
		return (
			<Accordion.Collapse eventKey={actKey || name}>
				<CardType {...props}>
					<ComponentOutlet components={body} />
				</CardType>
			</Accordion.Collapse>
		);
	}

	return (
		<CardType {...props}>
			<ComponentOutlet components={body} />
		</CardType>
	);
}
CardContent.defaultProps = {
	type: 'Body',
};
export default CardContent;

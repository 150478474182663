import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LoaderCtx } from '@outsource-school/helper';
import './setupAxios';
import './App.css';

import Bootstrap from './components/Bootstrap';
import FlashMessages from './components/FlashMessages';
import HttpError from './components/HttpError';
import LeaveMessage from './components/LeaveMessage';
import OrgStyles from './components/OrgStyles';
import StoryblokRoutes from './components/StoryblokRoutes';

function onError(error, reload) {
	Sentry.captureException(error);
	return <HttpError error={error} reload={reload} />;
}

function App() {
	return (
		<LoaderCtx.Provider value={{ onError, timeout: 1000 }}>
			<OrgStyles>
				<Router>
					<FlashMessages>
						<LeaveMessage>
							<Bootstrap>
								<StoryblokRoutes />
							</Bootstrap>
						</LeaveMessage>
					</FlashMessages>
				</Router>
			</OrgStyles>
		</LoaderCtx.Provider>
	);
}

export default App;

import React, { useContext, useRef, useEffect, useState } from 'react';
import { FormContext } from '@outsource-school/helper';
import get from 'lodash/get';

import { StoryblokDataCtx } from '../components/StoryblokData';
import { ComponentOutletCtx } from '../components/ComponentOutlet';
import template from '../utils/template';
import { fromEvent } from 'rxjs';
import stringifyCircular from '../../src/utils/stringifyCircular';

const sandbox = [
	'allow-forms',
	'allow-modals',
	'allow-orientation-lock',
	'allow-pointer-lock',
	'allow-popups-to-escape-sandbox',
	'allow-popups',
	'allow-presentation',
	'allow-same-origin',
	'allow-scripts',
].join(' ');

function FormFrame({ name, src }) {
	const [height, setHeight] = useState();
	const [win, setWin] = useState();
	const { form, onSubmit, setField } = useContext(FormContext);
	const context = useContext(ComponentOutletCtx);
	const sbData = useContext(StoryblokDataCtx);
	const ref = useRef();

	const { _uid } = context.component;

	useEffect(() => {
		const sub = fromEvent(window, 'message').subscribe((e) => {
			const height = get(e, 'data.height');
			if (height) setHeight(height);

			const event = get(e, 'data.event');
			if (event === 'pks-processing') onSubmit(e);
			if (event === 'outsource-school-submit') onSubmit(e);
			if (event === 'outsource-school-pks-load') {
				const msg = JSON.parse(stringifyCircular({ event: 'form', form, _uid }));
				win.postMessage(msg, '*');
			}
			if (event === 'outsource-product-information') {
				setField('productInfo', e.data.productData);
			}
		});

		return () => sub.unsubscribe();
	}, [win, form, onSubmit, _uid, setField]);

	useEffect(() => {
		const contentWindow = get(ref, 'current.contentWindow');
		if (win === contentWindow) return;
		setWin(contentWindow);
	}, [win]);

	return (
		<iframe
			allow="autoplay; fullscreen"
			allowFullScreen
			className="embed-responsive-item col-12"
			frameBorder="0"
			name={_uid}
			ref={ref}
			src={template(src, sbData)}
			sandbox={window.Cypress && sandbox}
			style={{ height, minHeight: '100vh' }}
			title={_uid}
		/>
	);
}
export default FormFrame;

import React, { useContext } from 'react';
import isArray from 'lodash/isArray';
import ComponentOutlet from '../components/ComponentOutlet';
import { StoryblokDataCtx } from '../components/StoryblokData';
import template from '../utils/template';

function Container({ body, className, height, width, style, bsSize, name, ...props }) {
	const sbData = useContext(StoryblokDataCtx);
	const sty = isArray(style && style.options)
		? style.options.reduce((o, { name, value }) => ({ ...o, [name]: value }), {})
		: style;

	return (
		<div
			{...props}
			name={template(name, sbData)}
			className={className}
			style={{ ...sty, height, width }}
		>
			<ComponentOutlet components={body} />
		</div>
	);
}
export default Container;

import React, { useContext } from 'react';
import classnames from 'classnames';
import BsButton from 'react-bootstrap/Button';
import concat from 'lodash/concat';
import get from 'lodash/get';

import ComponentOutlet from '../components/ComponentOutlet';

import { FormContext } from '@outsource-school/helper';
import { ToggleContext } from '../components/Toggle';
import { InputCtx } from './Input';

function Button({ as, body, name, state, className, text, textAlign, type, rounded, ...props }) {
	const toggle = useContext(ToggleContext);
	const formData = useContext(FormContext);
	const inputRef = useContext(InputCtx);
	const stateProps = concat(state).reduce((o, k) => ({ ...o, [k]: true }), {});
	const bs5 = String(textAlign).replace('left', 'start').replace('right', 'end');
	const cls = classnames(className, bs5, text, rounded, `btn-tp-${type}`);
	const htmlType = ['button', 'reset', 'submit'].find((t) => t === type) || 'button';
	const As = type === 'label' ? 'div' : as || null;

	if (type === 'close' && toggle) {
		return (
			<BsButton
				className={cls}
				name={name}
				{...stateProps}
				{...props}
				onClick={toggle.hide}
				type="button"
			>
				{!String(body) && name}
				<ComponentOutlet components={body} />
			</BsButton>
		);
	}

	function onClick(event) {
		if (get(formData, 'form.__parentForm') && type === 'submit') {
			event.preventDefault();
			event.stopPropagation();
			return formData.onSubmit(event);
		}

		if (inputRef.current && type === 'copy') {
			inputRef.current.select();
			inputRef.current.setSelectionRange(0, inputRef.current.value.length);
			document.execCommand('copy');
		}

		if (props.onClick) props.onClick(event);
	}

	return (
		<BsButton
			className={cls}
			as={As}
			name={name}
			{...stateProps}
			{...props}
			block={null}
			onClick={onClick}
			type={htmlType || 'button'}
		>
			{!String(body) && name}
			<ComponentOutlet components={body} />
		</BsButton>
	);
}
Button.defaultProps = { body: [] };
export default Button;

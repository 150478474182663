import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import concat from 'lodash/concat';
import get from 'lodash/get';
import { Feedback, FormGroup, FormGroupContext, Label, Input } from '@outsource-school/helper';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import InputMask from 'react-input-mask';

import validators from '../validation';
import ComponentOutlet from '../components/ComponentOutlet';
import template from '../utils/template';
import StoryblokData, { StoryblokDataCtx } from '../components/StoryblokData';

export const InputCtx = createContext({ current: null });
export const textComponents = ['rich-text-field', 'text-block'];
export function detectText(cmp) {
	if (textComponents.includes(cmp.component)) cmp.as = InputGroup.Text;
	return cmp;
}

function LabelCmp({ label, label_text, children, ...props }) {
	const formGroupState = useContext(FormGroupContext);
	const selected = formGroupState && formGroupState.selected;

	return (
		Boolean(concat(label).length || label_text) && (
			<Label {...props}>
				{!String(label) && label_text}
				{children}
				<StoryblokData data={{ selected }}>
					<ComponentOutlet components={label} />
				</StoryblokData>
			</Label>
		)
	);
}

function InputBlok({
	append,
	as: asProp,
	className,
	label_text,
	label,
	name,
	placeholder,
	prepend,
	rounded,
	type,
	validations: vCmps,
	value,
	...props
}) {
	const [validations, setValidations] = useState([]);
	const sbData = useContext(StoryblokDataCtx);
	const ref = useRef();
	const isGroup = Boolean(get(prepend, 'length') || get(append, 'length'));
	const isSwitch = type === 'switch';
	// const GroupAs = isSwitch ? LabelCmp : 'div';
	let As = isSwitch ? Form.Check : asProp;
	const classUtil = { 'form-control': props.mask };

	if (props.mask) As = InputMask;
	if (!isSwitch && get(prepend, 'length')) prepend.forEach(detectText);
	if (!isSwitch && get(append, 'length')) append.forEach(detectText);

	useEffect(() => {
		if (!vCmps || !vCmps.length) return;

		const validations = vCmps.map((cmp) => {
			const name = cmp.component.replace('validation-', '');
			return validators[name](cmp);
		});
		setValidations(validations);
	}, [vCmps]);

	if (type.includes('-hidden')) {
		const nType = type.replace('-hidden', '');
		return (
			<InputCtx.Provider value={ref}>
				<FormGroup
					groupName={template(name, sbData)}
					className={classnames(className, {
						'd-none': type === 'hidden',
						'mb-0': !concat(label).length && !placeholder,
					})}
				>
					<LabelCmp label={label} label_text={label_text} className="d-block">
						<Input
							{...props}
							ref={ref}
							className="sr-only"
							as={As}
							onClick={(e) => e.stopPropagation()}
							placeholder={template(placeholder, sbData)}
							type={nType}
							validations={validations}
							value={template(value, sbData)}
						/>
					</LabelCmp>
				</FormGroup>
			</InputCtx.Provider>
		);
	}

	return (
		<InputCtx.Provider value={ref}>
			<FormGroup
				groupName={template(name, sbData)}
				className={classnames(className, {
					'd-none': type === 'hidden',
					'mb-0': !concat(label).length && !placeholder,
				})}
			>
				{!isSwitch && <LabelCmp label={label} label_text={label_text} />}

				{isGroup && (
					<InputGroup>
						{Boolean(prepend.length) && <ComponentOutlet components={prepend} />}
						<Input
							{...props}
							ref={ref}
							className={classnames(rounded, classUtil)}
							as={As}
							label={<LabelCmp label={label} label_text={label_text} />}
							onClick={(e) => e.stopPropagation()}
							placeholder={template(placeholder, sbData)}
							type={type}
							validations={validations}
							value={template(value, sbData)}
						/>
						{Boolean(append.length) && <ComponentOutlet components={append} />}
						<Feedback />
					</InputGroup>
				)}

				{!isGroup && (
					<Input
						{...props}
						ref={ref}
						className={classnames(className, rounded, classUtil)}
						as={As}
						label={<LabelCmp label={label} label_text={label_text} />}
						onClick={(e) => e.stopPropagation()}
						placeholder={template(placeholder, sbData)}
						type={type}
						validations={validations}
						value={template(value, sbData)}
					/>
				)}

				<Feedback />
			</FormGroup>
		</InputCtx.Provider>
	);
}
InputBlok.defaultProps = {
	type: '',
};
export default InputBlok;

import mapKeys from 'lodash/mapKeys';

const keepInstances = [Blob];

function flattenCircular(inputObj, formStyle, separator = '.') {
	const cache = new Map();
	const flat = (obj) =>
		Object.keys(obj).reduce((acc, key) => {
			const value = obj[key];
			const keep = keepInstances.find((k) => value instanceof k);
			if (typeof value !== 'object' || value !== null || keep) {
				return {
					...acc,
					[key]: value,
				};
			}

			if (typeof value === 'object' && value !== null) {
				// Duplicate reference found, discard key
				if (cache.has(value)) return acc;
				// Store value in our collection
				cache.set(value);
			}

			const flattenedChild = flat(value);
			const flattenKey = (childAcc, childKey) => ({
				...childAcc,
				[`${key}${separator}${childKey}`]: flattenedChild[childKey],
			});

			return {
				...acc,
				...Object.keys(flattenedChild).reduce(flattenKey, {}),
			};
		}, {});

	if (formStyle) {
		return mapKeys(flat(inputObj), (v, k) => {
			const [start, ...rest] = k.split('.');
			return start + rest.map((k) => `[${k}]`).join('');
		});
	}

	return flat(inputObj);
}

export default flattenCircular;

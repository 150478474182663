import memoize from 'lodash/memoize';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';

export function cache(fn, time = 500, resolver) {
	const clearCache = debounce(function () {
		memCache.cache.clear();
	}, time);
	const memCache = memoize(fn, function (...args) {
		clearCache();
		if (!isFunction(resolver)) return args[0];
		return resolver(...args);
	});

	return memCache;
}

export default cache;

import _capitalize from 'lodash/capitalize';
import _find from 'lodash/get';
import _get from 'lodash/get';
import _template from 'lodash/template';
import map from 'lodash/map';
import moment from 'moment';

function _map(data, fn, delimiter) {
	return map(data, fn).join(delimiter || ', ');
}

function template(str, $data) {
	try {
		const data = { ...$data };
		function _show(data) {
			// Note: cache should not be re-used by repeated calls to JSON.stringify.
			const cache = new Map();
			const str = JSON.stringify(
				data,
				function (key, value) {
					if (value && value.btoa && value.atob) {
						return '[Window]';
					}

					if (typeof value === 'object' && value !== null) {
						// Duplicate reference found, discard key
						if (cache.has(value)) return;
						// Store value in our collection
						cache.set(value);
					}
					return value;
				},
				2
			);

			return `~~~\n${str}\n~~~`;
		}

		if (!str) return str;

		const bodyStr = String(str)
			// eslint-disable-next-line
			.replace(/\{\{\s*_fileSafe\((.+)\)\s*\}\}/g, '<%=_fileSafe("$1")%>')
			.replace(/\{\{\s*_show\((.+)\)\s*\}\}/g, '<%=_show($1)%>')
			.replace(/\{\{\s*_map\((.+)\)\s*\}\}/g, '<%=_map($1)%>')
			.replace(/\{\{\s*_find\((.+)\)\s*\}\}/g, '<%=_find($1)%>')
			.replace(/\{\{\s*_capitalize\((.+)\)\s*\}\}/g, '<%=_capitalize($1)%>')
			/** @description
			 * grabs anything that looks like this => {{ variable }}
			 * $1 being "variable"
			 * @prop {string} failSafeTemplate above is used for this purpose
			 * */
			// eslint-disable-next-line
			.replace(/\{\{([^\}]*[^\}]*)\}\}/g, `<%=get(_all, "$1") %>`);

		function _fileSafe(str) {
			const safeName = String(_get(data, str) || '').replace(/[\\/:*?"<>|,]/g, '_');
			return encodeURIComponent(safeName);
		}

		return _template(bodyStr)({
			...data,
			_all: data,
			_capitalize,
			_fileSafe,
			_find,
			_map,
			_show,
			get: _get,
			moment,
		});
	} catch (e) {
		console.log(e);
		return str;
	}
}

export default template;

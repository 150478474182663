import React, { useContext } from 'react';
import concat from 'lodash/concat';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { Form } from '@outsource-school/helper';
import { useHistory, useLocation } from 'react-router-dom';
import buildUrl from 'axios/lib/helpers/buildURL';

import { FormProvider } from './ExternalForm';
import ComponentOutlet from '../components/ComponentOutlet';
import { StoryblokDataCtx } from '../components/StoryblokData';

function SearchForm({ url, state, body, ...props }) {
	const history = useHistory();
	const { pathname } = useLocation();
	const sbData = useContext(StoryblokDataCtx);
	const { submitOnly } = concat(state).reduce((o, k) => ({ ...o, [k]: true }), {});

	function onForm(event, data) {
		const { form } = data || event;

		const cached_url = `/${get(url, 'cached_url') || pathname}`.replace(/^\/\//i, '/');
		const statePath = buildUrl(cached_url, omit(form, ['needsValidation']));
		history.push(statePath);
	}

	return (
		<Form
			{...props}
			onSubmit={submitOnly && onForm}
			onForm={!submitOnly && onForm}
			initialState={sbData.query}
			validateOnSubmit
		>
			<FormProvider>
				<ComponentOutlet components={body} />
			</FormProvider>
		</Form>
	);
}
export default SearchForm;

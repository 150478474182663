import React, { createContext, useContext } from 'react';
import ComponentOutlet from '../components/ComponentOutlet';

export const HoverGroupCtx = createContext();
function HoverGroup({ body }) {
	const group = useContext(HoverGroupCtx);
	const id = Number(group || 0) + 1;
	const cls = `hover-group-${id}`;

	return (
		<HoverGroupCtx.Provider value={id}>
			<ComponentOutlet components={body} className={cls} />
		</HoverGroupCtx.Provider>
	);
}
export default HoverGroup;

function RedirectCmp({ name }) {
	const _redirect = sessionStorage.getItem(name);
	sessionStorage.removeItem(name);

	const isHome = _redirect === 'http://localhost:3000/';

	if (_redirect && !isHome && _redirect !== window.location.href) window.location = _redirect;
	return null;
}
export default RedirectCmp;

import React, { useContext } from 'react';
import classnames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import concat from 'lodash/concat';
import BsImage from 'react-bootstrap/Image';
import { StoryblokDataCtx } from '../components/StoryblokData';
import template from '../utils/template';

function Image({ body, state, as, src, className, width, style, ...props }) {
	const sbData = useContext(StoryblokDataCtx);
	const stateProps = concat(state).reduce((o, k) => ({ ...o, [k]: true }), {});
	const As = as || BsImage;
	const img = template(src, sbData);
	const cls = classnames(className);
	const widthNum = Number(width) || undefined;

	if (stateProps.clip) {
		const stateCls = state.map(kebabCase).join(' ');
		return (
			<div
				className={classnames('img-clip', stateCls, className)}
				style={{ minWidth: widthNum, width: widthNum, minHeight: widthNum }}
			>
				<As {...props} src={img} style={style} />
			</div>
		);
	}

	const sty = { ...style, minWidth: widthNum, width: widthNum };
	return <As {...stateProps} {...props} className={cls} src={img} style={sty} />;
}
export default Image;

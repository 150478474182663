import axios from 'axios';
import buildUrl from 'axios/lib/helpers/buildURL';
import moment from 'moment';
import Promise from 'bluebird';

import get from 'lodash/get';
import invoke from 'lodash/invoke';
import merge from 'lodash/merge';

import cache from './utils/cache';
import { isLocalhost } from './serviceWorker';

export const cached = cache(axiosRequestCache, 2 * 1000);
export const friendsCache = cache(axiosRequestCache, 2 * 1000);
export const sessionCache = cache(axiosRequestCache, Number(moment.duration(2, 'hours')));
export const storyblokCache = cache(axiosRequestCache, Number(moment.duration(2, 'hours')));
export const usersAvatarCache = cache(axiosRequestCache, Number(moment.duration(2, 'days')));

export const shortCache = cache(axiosRequestCache, Number(moment.duration(15, 'minutes')));
export const medCache = cache(axiosRequestCache, Number(moment.duration(2, 'hours')));
export const longCache = cache(axiosRequestCache, Number(moment.duration(2, 'days')));

function axiosRequestCache(k, req) {
	const response = req.adapter(req);
	req.adapter = () => response;

	return req;
}

axios.interceptors.response.use(null, (error) => {
	const config = get(error, 'config') || {};
	const connectionError = !get(error, 'response.status') || get(error, 'response.status') >= 500;
	const isOrgInfo = String(config.url).includes('/api/organization/info');
	config.retry = config.retry || 0;

	if (!isOrgInfo && config.method === 'get' && config.retry < 5 && connectionError) {
		config.retry = config.retry + 1;
		delete config.adapter;
		return Promise.delay(config.retry * 200).then(() => axios.request(config));
	}

	return Promise.reject(error);
});

axios.interceptors.request.use((request) => {
	if (request.url.includes('/passport')) sessionCache.cache.clear();
	if (request.url.includes('/ref/fid/')) friendsCache.cache.clear();

	const apiKey = get(request, 'data.apiKey') || invoke(request, 'data.get', 'apiKey');
	const isLarge = apiKey && !isLocalhost;
	if (isLarge) request.url = `https://app-lg.simplysop.com${request.url}`;
	if (request.method !== 'get' && request.url.includes('/user')) sessionCache.cache.clear();
	if (request.method !== 'get') return request;
	if (request.url.includes('cache=none')) return request;

	const cacheUrl = buildUrl(request.url, request.params);
	if (request.url.includes('cache=sm')) return shortCache(cacheUrl, request);
	if (request.url.includes('cache=md')) return medCache(cacheUrl, request);
	if (request.url.includes('cache=lg')) return longCache(cacheUrl, request);

	if (request.url.includes('api.storyblok.com')) return storyblokCache(cacheUrl, request);
	if (request.url.includes('/session')) return sessionCache(cacheUrl, request);
	if (request.url.includes('/associate')) return friendsCache(cacheUrl, request);
	if (request.url.includes('/user/friend/')) return usersAvatarCache(cacheUrl, request);
	if (request.url.includes('/videos/thumbnails/')) return longCache(cacheUrl, request);

	return cached(cacheUrl, request);
});

merge(axios.defaults, {});

import React, { useContext } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import StoryblokClient from 'storyblok-js-client';

import { token } from '../api/storyblok';
import { StoryblokDataCtx } from '../components/StoryblokData';
import template from '../utils/template';

const Storyblok = new StoryblokClient({ accessToken: token });

function RichTextField({ as, content, className, ...props }) {
	const sbData = useContext(StoryblokDataCtx);
	if (!content) return <div />;
	const source =
		get(content, 'content.0.type') === 'code_block'
			? get(content, 'content.0.content.0.text')
			: Storyblok.richTextResolver.render(content);
	const __html = template(source, sbData);

	const As = as || 'div';

	return (
		<As
			className={classnames('text-break rmd', className, { 'd-inline-block': !className })}
			{...props}
			dangerouslySetInnerHTML={{ __html }}
		/>
	);
}
export default RichTextField;

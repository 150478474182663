import React from 'react';
import { Timer } from '@outsource-school/helper';
import CircularProgress from '@material-ui/core/CircularProgress';

function Fallback() {
	return (
		<Timer>
			<CircularProgress />
		</Timer>
	);
}

export default Fallback;

import React, { Fragment, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { Url } from 'url';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

import get from 'lodash/get';
import concat from 'lodash/concat';
import reduce from 'lodash/reduce';

import { StoryblokDataCtx } from '../components/StoryblokData';
import ComponentOutlet from '../components/ComponentOutlet';
import template from '../utils/template';
import { LeaveMessageCtx } from '../components/LeaveMessage';

function Link({
	as,
	body,
	children,
	className,
	name,
	rounded,
	state,
	text,
	textAlign,
	url,
	wrap,
	map: mappedKeys,
	...props
}) {
	const sbData = useContext(StoryblokDataCtx);
	const { setSafeRoute } = useContext(LeaveMessageCtx);
	const { linktype, cached_url } = url;
	const stateProps = concat(state).reduce((o, k) => ({ ...o, [k]: true }), {});
	const As = sbData.dropdown ? Dropdown.Item : as || Button;
	const bs5 = String(textAlign).replace('left', 'start').replace('right', 'end');
	const cls = classnames(className, text, bs5, rounded, { 'd-block btn-block': stateProps.block });
	const Wrap = wrap || Fragment;

	function baseTemplate(attrs) {
		function onMouseEnter(event) {
			if (attrs.onMouseEnter) attrs.onMouseEnter(event);
			if (!String(attrs.to).includes('/')) return setSafeRoute(true);

			const { pathname } = new Url(event.target.href);
			if (window.location.pathname === pathname) return setSafeRoute(true);
		}

		function onMouseLeave(event) {
			if (attrs.onMouseLeave) attrs.onMouseLeave(event);
			return setSafeRoute(null);
		}

		return (
			<Wrap>
				<As
					{...attrs}
					{...stateProps}
					{...props}
					block={null}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					className={cls}
				>
					{!String(body) && template(name, sbData)}
					<ComponentOutlet components={body} />
				</As>
			</Wrap>
		);
	}

	if (get(cached_url, '0') === '?') {
		return baseTemplate({
			as: NavLink,
			exact: !cached_url,
			to: { search: template(cached_url, sbData) },
		});
	}

	if (linktype !== 'story') {
		return baseTemplate({ as: 'a', href: template(cached_url, sbData) });
	}

	if (cached_url.includes('/-')) {
		const mappedData = reduce(
			get(mappedKeys, 'options'),
			(o, { name: k, value: v }) => Object.assign(o, { [k]: template(v, sbData) }),
			{}
		);
		const urlTmp = cached_url
			.split('/')
			.map((v) => (v[0] === '-' ? `{{${v.substr(1)}}}` : v))
			.join('/');

		return baseTemplate({
			as: NavLink,
			exact: !urlTmp,
			to: template(`/${urlTmp}`, mappedData),
		});
	}

	return baseTemplate({
		as: NavLink,
		exact: !cached_url,
		to: template(`/${cached_url}`, sbData),
	});
}
Link.defaultProps = {
	url: {},
};
export default Link;

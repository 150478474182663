import React, { useContext } from 'react';
import concat from 'lodash/concat';
import ComponentOutlet from '../components/ComponentOutlet';
import { HoverGroupCtx } from './HoverGroup';

function HoverContent({ body, state }) {
	const id = useContext(HoverGroupCtx);
	const stateProps = concat(state).reduce((o, k) => ({ ...o, [k]: true }), {});
	const cls = stateProps.show ? `hover-content-show-${id}` : `hover-content-${id}`;

	return <ComponentOutlet components={body} className={cls} />;
}
export default HoverContent;

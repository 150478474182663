import React, { createContext, useState } from 'react';
import { ToggleContext } from './Toggle';
import ComponentOutlet from './ComponentOutlet';

export const FullScreenModalCtx = createContext();
function FullScreenModalWrapper({ children }) {
	const [content, setContent] = useState();
	function hide() {
		setContent(null);
	}

	if (content)
		return (
			<ToggleContext.Provider value={{ hide }}>
				<ComponentOutlet components={content} />
			</ToggleContext.Provider>
		);

	return <FullScreenModalCtx.Provider value={setContent}>{children}</FullScreenModalCtx.Provider>;
}

export default FullScreenModalWrapper;

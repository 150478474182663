import conditionalRendering from '../utils/conditionalRendering';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import axios from 'axios';
import template from '../utils/template';

export default function required({ condition, url, method, message }, sbData) {
	return (name, value, form) => {
		if (!get(condition, 'comparators')) return;
		const data = { ...sbData, value, form };
		if (!url) {
			const passing = condition.comparators.every((c) => conditionalRendering(c, data));
			if (!passing) return template(message, data);
			return;
		}

		const action = template(url, data);
		return axios[lowerCase(method || 'get')](action).then(({ data: response }) => {
			const newData = { ...data, response };
			const passing = condition.comparators.every((c) => conditionalRendering(c, newData));
			if (!passing) return template(message, newData);
		});
	};
}

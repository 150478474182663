import React from 'react';
import { Loader } from '@outsource-school/helper';
import { useParams, useHistory, useLocation } from 'react-router';
import { parse } from 'querystring';

import get from 'lodash/get';
import ComponentOutlet from './ComponentOutlet';
import getPage from '../api/storyblok';
import EditorDetector from './EditorDetector';
import StoryblokData from './StoryblokData';
import Page from '../storyblok/Page';
import FullScreenModalWrapper from './FullScreenModalWrapper';

function useQuery() {
	return parse(useLocation().search.substr(1));
}

export const StoryblokPageCtx = React.createContext({});
function StoryblokPage({ page }) {
	const history = useHistory();
	const params = useParams();
	const location = useLocation();
	const query = useQuery();

	return (
		<Loader services={() => [getPage(page)]} required={[page]} unmountOnExit={false}>
			{({ results, reload, setResults }) => {
				const [{ data }] = results;
				const content = get(data, 'story.content');
				if (!content) return null;

				const { body, layout } = content;
				function setPage(newData) {
					const newRes = [{ ...results[0], data: newData }];
					setResults(newRes);
				}

				return (
					<StoryblokPageCtx.Provider value={{ page: data, reload, setPage }}>
						<EditorDetector />
						<StoryblokData
							data={{
								window,
								windowLocation: window.location,
								params,
								query,
								history,
								location,
							}}
						>
							<FullScreenModalWrapper>
								{content.component === 'page' && <Page body={body} layout={layout} />}
								{content.component !== 'page' && <ComponentOutlet components={content} />}
							</FullScreenModalWrapper>
						</StoryblokData>
					</StoryblokPageCtx.Provider>
				);
			}}
		</Loader>
	);
}

// const resolver = (prevProps, nextProps) => prevProps.page === nextProps.page;
// export default React.memo(StoryblokPage, resolver);
export default StoryblokPage;

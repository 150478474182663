import React, { useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { FormContext, Loader } from '@outsource-school/helper';
import get from 'lodash/get';

import { SessionCtx } from './LoginGuard';

const v3 = '6LcjWOoZAAAAABtFX_Q4dy8xMrUZ4VfBXm7P2sYH';
function gReady() {
	if (gReady.promise) return gReady.promise;

	gReady.promise = new Promise((res) => {
		window.gReady = res;
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = `https://www.google.com/recaptcha/api.js?render=${v3}&onload=gReady`;

		const head = document.getElementsByTagName('head')[0];
		head.appendChild(script);
	});

	return gReady.promise;
}

export function services() {

	const config = { action: "login" };
	return [
		gReady()
			.then(() => window.grecaptcha.execute(v3, config))
			.then((response) => axios.post('/api/passport/recaptcha', { response })),
	];
}

// Recaptcha v3 no checkbox
function RecaptchaGaurd({ children }) {
	const session = useContext(SessionCtx);
	if (get(session, 'data.recaptcha.verified')) return children;

	return (
		<Loader services={services}>
			{({ results: [response] }) => {
				if (get(response, 'data.verified') === false) return <Recaptcha children={children} />;
				return children;
			}}
		</Loader>
	);
}

// Recaptcha v2 checkbox to verify user
function Recaptcha({ children }) {
	const { form, setField } = useContext(FormContext);
	const session = useContext(SessionCtx);
	const ref = useRef();

	useEffect(() => {
		if (!ref.current) return;

		window.grecaptcha.render(ref.current, {
			// V2 key
			sitekey: '6LfDWOoZAAAAAFUS6OwtmmDembeAITRq4r4GrYGI',
			callback(response) {
				setField('_grecaptcha', response);
			},
		});
	}, [ref, setField]);

	if (form._grecaptcha) return children;
	if (get(session, 'data.recaptcha.verified') === false)
		return <div class="g-recaptcha" ref={ref}></div>;

	return children;
}

export default RecaptchaGaurd;

import React from 'react';
import classnames from 'classnames';
import concat from 'lodash/concat';
import BsNavbar from 'react-bootstrap/Navbar';
import ComponentOutlet from '../components/ComponentOutlet';

function Navbar({ body, bg, brand, className, toggle, shadow, state, ...rest }) {
	const stateProps = concat(state).reduce((o, k) => ({ ...o, [k]: true }), {});
	const brandCmp = concat(brand).map((link) =>
		Object.assign(link, { as: BsNavbar.Brand, activeClassName: 'active' })
	);
	const variant = bg && bg !== 'light' ? 'dark' : 'light';
	const cls = classnames(className, `${shadow}`);
	const props = { ...rest, className: cls };

	if (stateProps.collapse) {
		return (
			<BsNavbar expand="lg" bg={bg} {...props} variant={variant}>
				<ComponentOutlet components={brandCmp} />
				<BsNavbar.Toggle aria-controls="basic-navbar-nav" />
				<BsNavbar.Collapse id="basic-navbar-nav">
					<ComponentOutlet components={body} />
				</BsNavbar.Collapse>
			</BsNavbar>
		);
	}

	if (stateProps.vertical) {
		return (
			<BsNavbar bg={bg} {...props} variant={variant}>
				<ComponentOutlet components={brandCmp} />
				<BsNavbar.Toggle />
				<BsNavbar.Collapse className="vertical">
					<ComponentOutlet components={body} />
				</BsNavbar.Collapse>
			</BsNavbar>
		);
	}

	return (
		<BsNavbar bg={bg} {...props} variant={variant}>
			<ComponentOutlet components={brandCmp} />
			<ComponentOutlet components={body} />
		</BsNavbar>
	);
}
Navbar.defaultProps = {
	brand: {},
};
export default Navbar;

import React from 'react';
import axios from 'axios';

import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import memoize from 'lodash/memoize';

import { Loader } from '@outsource-school/helper';
import StoryblokData from './StoryblokData';

export const setStyles = memoize((cssFile) => {
	if (!cssFile) return null;

	const $styleElem = document.querySelector('link[type="text/css"][href^="/static/css/"]');
	if ($styleElem && isFunction($styleElem.remove)) $styleElem.remove();

	const head = document.getElementsByTagName('head')[0];
	const link = document.createElement('link');
	link.id = 'orgStyles';
	link.rel = 'stylesheet';
	link.type = 'text/css';
	link.href = `/${cssFile}`;
	link.media = 'all';
	head.appendChild(link);
});

export function getStyles() {
	return axios.get('/api/organization/info.json');
}

function OrgStyles({ children }) {
	return (
		<Loader services={() => [getStyles()]} onError={() => children}>
			{({ results }) => {
				const organizationInfo = get(results, '0.data');
				setStyles(get(organizationInfo, 'cssFile'));

				return <StoryblokData data={{ organizationInfo }}>{children}</StoryblokData>;
			}}
		</Loader>
	);
}

export default OrgStyles;

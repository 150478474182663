import React, { Suspense, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'querystring';
import classnames from 'classnames';
import { CatchError } from '@outsource-school/helper';

import clone from 'lodash/clone';
import concat from 'lodash/concat';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

import { token } from '../api/storyblok';
import manifest from '../manifest';
import bsClasses from '../utils/bsClasses';
import conditionalRendering from '../utils/conditionalRendering';
import { StoryblokPageCtx } from './StoryblokPage';
import { StoryblokDataCtx } from './StoryblokData';

import Fallback from './Fallback';
import ReactComment from './ReactComment';
import template from '../utils/template';
import StoryblokErrors from './StoryblokErrors';

export const sbSync = debounce(function () {
	const { storyblok } = window;
	if (!storyblok) return;

	// Optionally init if you have not provided the ?t parameter to the script tag already.
	storyblok.init({ accessToken: token });
}, 200);

function useQuery() {
	return parse(useLocation().search.substr(1));
}

export const ComponentOutletCtx = React.createContext({});
function ComponentOutlet({ components, prefix, switch: useFirst, ...props }) {
	const { page } = useContext(StoryblokPageCtx);
	const sbData = useContext(StoryblokDataCtx);
	let cmps = concat(components).filter((cmp) => cmp && compareComp(cmp.render_when));
	const query = useQuery();
	const spaceId = query['_storyblok_tk[space_id]'];
	const storyId = get(page, 'story.id');

	useEffect(() => {
		sbSync();
	}, []);

	function styleMap(style) {
		return template(style, sbData);
	}

	function compareComp(render_when) {
		if (!get(render_when, 'comparators')) {
			return true;
		}

		return render_when.comparators.every((c) => conditionalRendering(c, sbData));
	}

	if (useFirst) cmps = cmps.slice(0, 1);

	return cmps.map((component, index) => {
		Object.assign(component, { __uniqueId: `${prefix || ''}_${component._uid}` });
		const {
			__uniqueId,
			_editable,
			_uid,
			bsSize,
			className,
			component: _n,
			render_when,
			style,
			styles,
			...rest
		} = clone(component);
		const ExtComponent = manifest[_n] || 'null';
		const bsCls = Array.from(bsClasses({ ...bsSize })).join(' ');
		const comObj = {
			id: storyId,
			name: _n,
			space: spaceId,
			uid: _uid,
		};
		const comment = `<!--#storyblok#${JSON.stringify(comObj)}-->`;
		const styleOpts = mapValues(keyBy(get(styles, 'options'), 'name'), 'value');
		const mappedStyles = Object.assign(mapValues(styleOpts, styleMap), style);

		if (rest && !rest.name) delete rest.name;
		if (props && !props.name) delete props.name;

		return (
			<CatchError key={_uid} onError={(props) => <StoryblokErrors {...props} />}>
				<Suspense fallback={<Fallback />}>
					<ComponentOutletCtx.Provider value={{ component, index }}>
						{_editable && <ReactComment comment={_editable || comment} />}
						<ExtComponent
							style={mappedStyles}
							{...rest}
							{...props}
							className={classnames(bsCls, className, props.className)}
						/>
					</ComponentOutletCtx.Provider>
				</Suspense>
			</CatchError>
		);
	});
}

export default ComponentOutlet;

import React, { useCallback, useState, useContext, createContext } from 'react';
import classnames from 'classnames';
import axios from 'axios';

import debounce from 'lodash/debounce';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { FormGroup, Input, Loader } from '@outsource-school/helper';

import { detectText } from './Input';
import template from '../utils/template';
import StoryblokData, { StoryblokDataCtx } from '../components/StoryblokData';
import ComponentOutlet from '../components/ComponentOutlet';

export const searchKey = '_ta_key';
export const linkKey = '_ta_link';

export const TypeaheadCtx = createContext();
function Typeahead({
	append,
	body,
	className,
	data,
	display,
	method,
	name,
	prepend,
	testUrl,
	url,
	...props
}) {
	const sbData = useContext(StoryblokDataCtx);
	if (get(prepend, 'length')) prepend.forEach(detectText);
	if (get(append, 'length')) append.forEach(detectText);
	const [query, setQuery] = useState();
	const [show, setShow] = useState(false);

	function toggle() {
		setShow((s) => !s);
	}

	// eslint-disable-next-line
	const requestOptions = useCallback(
		debounce((target) => {
			if (!target.value) return;
			setQuery(target.value);
			setShow(true);
		}, 1000),
		[]
	);

	const endpoint = get(sbData, 'query._storyblok') && testUrl ? testUrl : template(url, sbData);

	function getOptions(query) {
		const tData = { ...sbData, typeahead: query };
		const params = reduce(
			get(data, 'options'),
			(o, { name: k, value: v }) => Object.assign(o, { [template(k, tData)]: template(v, tData) }),
			{}
		);
		const payload = method === 'get' ? { params } : params;
		return axios[method](endpoint, payload);
	}

	return (
		<Dropdown as="div" onToggle={toggle} show={show} style={{ width: '100%' }}>
			<FormGroup groupName={template(name, sbData)} className="mb-0">
				<InputGroup className={classnames(className, 'input-group-rounded')}>
					{Boolean(prepend && prepend.length) && <ComponentOutlet components={prepend} />}

					<Input onChange={(event) => requestOptions(event.target)} autoComplete="off" {...props} />

					{Boolean(append && append.length) && <ComponentOutlet components={append} />}
				</InputGroup>
			</FormGroup>
			<Dropdown.Menu>
				<Loader services={() => [getOptions(query)]} required={[query]}>
					{({ results: [{ data: typeahead }] }) => (
						<StoryblokData data={{ typeahead, dropdown: true }}>
							<ComponentOutlet components={body} />
						</StoryblokData>
					)}
				</Loader>
			</Dropdown.Menu>
		</Dropdown>
	);
}
export default Typeahead;

import axios from 'axios';
import { of, from, BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, map, catchError, mergeMap } from 'rxjs/operators';

import cache from '../utils/cache';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

export const debugMode =
	window.location.href.includes('_storyblok=') || window.location.href.includes('localhost');
export const DEBOUNCE_TIME = debugMode ? 0 : 9 * 1000;
export const CDN_TIME = 15 * 1000;
export const version = debugMode ? 'draft' : 'published';
export const token = '74i3j9rk4OuNNfQcUaw9rQtt';

export function getVersion() {
	const cv = debugMode ? Number(new Date()) : Math.round(+new Date() / CDN_TIME).toString(36);
	const params = { token, cv };
	return from(axios.get('//api.storyblok.com/v1/cdn/spaces/me', { params }));
}
export const cachedVersion = cache(getVersion, DEBOUNCE_TIME);
export const version$ = new BehaviorSubject(null).pipe(
	mergeMap(() => cachedVersion()),
	shareReplay()
);

export function get404Page(page: string = 'system/404') {
	const url = `//api.storyblok.com/v1/cdn/stories/${page}`;
	let cv: string;
	return version$.pipe(
		mergeMap((resp) => {
			cv = get(resp, 'data.space.version');
			const params = { token, version, cv };
			return from(axios.get(url, { params }));
		})
	);
}

export function getLinks(): Observable<any> {
	const url = `//api.storyblok.com/v1/cdn/links/`;
	return version$.pipe(
		mergeMap((resp) => {
			const cv = get(resp, 'data.space.version');
			const params = { token, cv };
			return from(axios.get(url, { params }));
		}),
		map(cloneDeep),
		catchError((e) => of([]))
	);
}

export function getPage(page: string): Observable<any> {
	const url = `//api.storyblok.com/v1/cdn/stories/${page}`;
	let cv: string;
	return version$.pipe(
		mergeMap((resp) => {
			cv = get(resp, 'data.space.version');
			const params = { token, version, cv };
			return from(axios.get(url, { params }));
		}),
		shareReplay(),
		map(cloneDeep),
		catchError((e) => {
			return get404Page();
		})
	);
}

export default cache(getPage, DEBOUNCE_TIME);

import { lazy } from 'react';
const manifest = {
	'accordion-toggle': lazy(() => import('./storyblok/AccordionToggle')),
	'ag-grid': lazy(() => import('./storyblok/AgGrid')),
	'app-link': lazy(() => import('./storyblok/AppLink')),
	'auto-upload': lazy(() => import('./storyblok/AutoUpload')),
	'basic-data': require('./storyblok/BasicData').default,
	'basic-repeater': require('./storyblok/BasicRepeater').default,
	'blank-page': require('./storyblok/BlankPage').default,
	'card-section': require('./storyblok/CardContent').default,
	'centered-page': lazy(() => import('./storyblok/CenteredPage')),
	'collapse-body': lazy(() => import('./storyblok/CollapseBody')),
	'collapse-toggle': lazy(() => import('./storyblok/CollapseToggle')),
	'conditional-switch': lazy(() => import('./storyblok/ConditionalSwitch')),
	'copy-form': lazy(() => import('./storyblok/CopyForm')),
	'data-group': lazy(() => import('./storyblok/DataGroup')),
	'drag-group': lazy(() => import('./storyblok/DragGroup')),
	'drag-item': lazy(() => import('./storyblok/DragItem')),
	'drop-area': lazy(() => import('./storyblok/DropArea')),
	'external-form': require('./storyblok/ExternalForm').default,
	'flash-alert': lazy(() => import('./storyblok/FlashAlert')),
	'form-frame': require('./storyblok/FormFrame').default,
	'form-group-toggle': require('./storyblok/FormGroupToggle').default,
	'form-group': lazy(() => import('./storyblok/FormGroup')),
	'full-screen-modal': lazy(() => import('./storyblok/FullScreenModal')),
	'group-name': lazy(() => import('./storyblok/GroupName')),
	'hover-content': require('./storyblok/HoverContent').default,
	'hover-group': require('./storyblok/HoverGroup').default,
	'hover-switch': lazy(() => import('./storyblok/HoverSwitch')),
	'layout-content': require('./storyblok/LayoutContent').default,
	'modal-section': lazy(() => import('./storyblok/ModalContent')),
	'nav-dropdown': require('./storyblok/NavDropdown').default,
	'print-section': lazy(() => import('./storyblok/PrintSection')),
	'print-toggle': lazy(() => import('./storyblok/PrintToggle')),
	'progress-bar': lazy(() => import('./storyblok/ProgressBar')),
	'redirect-detect': require('./storyblok/RedirectDetect').default,
	'resizable-section': lazy(() => import('./storyblok/ResizableSection')),
	'rich-text-field': require('./storyblok/RichTextField').default,
	'rtf-editor': lazy(() => import('./storyblok/RTFEditor')),
	'search-form': require('./storyblok/SearchForm').default,
	'side-drawer': require('./storyblok/SideDrawer').default,
	'snack-alert': lazy(() => import('./storyblok/SnackAlert')),
	'sortable-handle': lazy(() => import('./storyblok/SortableHandle')),
	'storage-form': require('./storyblok/StorageForm').default,
	'swiper-slide': lazy(() => import('./storyblok/SwiperSlide')),
	'tab-link': lazy(() => import('./storyblok/TabLink')),
	'text-block': require('./storyblok/TextBlock').default,
	'video-editor': lazy(() => import('./storyblok/VideoEditor')),
	alert: lazy(() => import('./storyblok/Alert')),
	autocomplete: lazy(() => import('./storyblok/Autocomplete')),
	breadcrumb: lazy(() => import('./storyblok/Breadcrumb')),
	breakpoint: lazy(() => import('./storyblok/Breakpoint')),
	button: require('./storyblok/Button').default,
	card: require('./storyblok/Card').default,
	ckfinder: lazy(() => import('./storyblok/CKFinder')),
	collapse: lazy(() => import('./storyblok/Collapse')),
	conditional: lazy(() => import('./storyblok/Conditional')),
	container: require('./storyblok/Container').default,
	cropper: lazy(() => import('./storyblok/Cropper')),
	diagram: lazy(() => import('./storyblok/Diagram')),
	divider: lazy(() => import('./storyblok/Divider')),
	dropzone: lazy(() => import('./storyblok/DropZone')),
	figure: lazy(() => import('./storyblok/Figure')),
	image: require('./storyblok/Image').default,
	input: require('./storyblok/Input').default,
	jumbotron: lazy(() => import('./storyblok/Jumbotron')),
	layout: require('./storyblok/Layout').default,
	link: require('./storyblok/Link').default,
	loader: lazy(() => import('./storyblok/Loader')),
	modal: lazy(() => import('./storyblok/Modal')),
	nav: require('./storyblok/Nav').default,
	navbar: require('./storyblok/Navbar').default,
	option: lazy(() => import('./storyblok/Option')),
	page: lazy(() => import('./storyblok/Page')),
	popover: lazy(() => import('./storyblok/Popover')),
	redirect: lazy(() => import('./storyblok/Redirect')),
	select: lazy(() => import('./storyblok/Select')),
	session: lazy(() => import('./storyblok/Session')),
	sidebar: lazy(() => import('./storyblok/Sidebar')),
	snackbar: lazy(() => import('./storyblok/Snackbar')),
	sortable: lazy(() => import('./storyblok/Sortable')),
	story: require('./storyblok/Story').default,
	swiper: lazy(() => import('./storyblok/Swiper')),
	tab: lazy(() => import('./storyblok/Tab')),
	tabs: lazy(() => import('./storyblok/Tabs')),
	tooltip: lazy(() => import('./storyblok/Tooltip')),
	typeahead: require('./storyblok/Typeahead').default,
	vimeo: lazy(() => import('./storyblok/Vimeo')),
};

export default manifest;

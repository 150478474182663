import React, { createContext, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';

export const FlashMessagesCtx = createContext([]);
function FlashMessages({ children }) {
	const [flashMessages, setFlashMessages] = useState([]);

	function setFlashMessage(msg) {
		setFlashMessages([...flashMessages, msg]);
	}

	return (
		<FlashMessagesCtx.Provider value={{ flashMessages, setFlashMessage, setFlashMessages }}>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={Boolean(flashMessages.length)}
				autoHideDuration={30 * 1000}
				onClose={() => setFlashMessages([])}
			>
				<div id="global-flash-messages">{flashMessages}</div>
			</Snackbar>
			{children}
		</FlashMessagesCtx.Provider>
	);
}
export default FlashMessages;

import { useContext, useEffect } from 'react';

import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import memoize from 'lodash/memoize';
import noop from 'lodash/noop';

import { StoryblokPageCtx } from './StoryblokPage';
import { token } from '../api/storyblok';
import { storyblokCache } from '../setupAxios';

const reloaders = {};
const stories = {};
const loadScript = memoize(() => {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${token}`;
	window.addEventListener('message', function (event) {
		const data = event.data;
		const storyId = get(data, 'story.id') || data.storyId;
		const reload = reloaders[storyId];
		const setPage = stories[storyId];

		if (!isFunction(reload)) return;
		if (['input'].includes(data.action)) setPage(data);

		if (['change', 'published'].includes(data.action)) {
			storyblokCache.cache.clear();
			reload();
		}
	});
	document.getElementsByTagName('head')[0].appendChild(script);
}, noop);

function EditorDetector() {
	const { page, reload, setPage } = useContext(StoryblokPageCtx);
	const id = get(page, 'story.id');
	reloaders[id] = reload;
	stories[id] = setPage;

	useEffect(() => {
		const editorMode = window.location.search.includes('_storyblok');
		if (editorMode) loadScript();
	}, []);

	return null;
}

export default EditorDetector;

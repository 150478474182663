import React, { createContext, useContext } from 'react';

export const ContainerContext = createContext();
function Container({ containerRef, children }) {
	const container = useContext(ContainerContext);

	return (
		<ContainerContext.Provider value={container || containerRef}>
			{children}
		</ContainerContext.Provider>
	);
}
export default Container;

import get from 'lodash/get';
import template from './template';
function comparatorCheck({ item, comparator: { comparator, value: compValue }, value }, sbData) {
	const dataValue = get(sbData, item);
	const rightValue = template(value, sbData);

	if (comparator === '.includes()') {
		const str = String(dataValue).toLowerCase();
		return str.includes(rightValue.toLowerCase());
	}

	if (comparator === '!.includes()') {
		const str = String(dataValue).toLowerCase();
		return !str.includes(rightValue.toLowerCase());
	}

	if (comparator === '//i') {
		return new RegExp(rightValue, 'i').test(dataValue);
	}

	if (comparator === '//') {
		return new RegExp(rightValue).test(dataValue);
	}

	if (comparator === 'Boolean') {
		return Boolean(dataValue);
	}

	if (comparator === '!Boolean') {
		return !Boolean(dataValue);
	}

	if (compValue === 'null' && comparator === '!=') {
		// eslint-disable-next-line
		return dataValue != null && dataValue != 'null';
	}

	if (compValue === 'null') {
		// eslint-disable-next-line
		return dataValue == null || dataValue == 'null';
	}

	if (comparator === '===') {
		return String(dataValue) === rightValue;
	}

	if (comparator === '!==') {
		return String(dataValue) !== rightValue;
	}

	if (comparator === '<') {
		return Number(dataValue) < Number(rightValue);
	}

	if (comparator === '>') {
		return Number(dataValue) > Number(rightValue);
	}

	return true;
}

export default comparatorCheck;

import React, { useContext } from 'react';
import { Loader } from '@outsource-school/helper';
import get from 'lodash/get';
import filter from 'lodash/filter';
import { Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';

import { getLinks } from '../api/storyblok';
import StoryblokPage from './StoryblokPage';
import LoginGuard from './LoginGuard';

export const StoryblokRoutesCtx = React.createContext({});
export function StoryblokRoute() {
	const { routesMap } = useContext(StoryblokRoutesCtx);
	const { pathname } = useLocation();
	const { path: rPath } = useRouteMatch();
	const path = pathname.length < 2 ? 'index' : pathname.substr(1);

	return (
		<LoginGuard>
			<StoryblokPage page={routesMap[rPath.substr(1)] || path} />
		</LoginGuard>
	);
}

function filterRoutes({ slug, is_folder }) {
	return !is_folder;
}

function StoryblokRoutes() {
	return (
		<Loader services={() => [getLinks()]}>
			{({ results: [{ data }], reload }) => {
				const links = get(data, 'links');
				const matchingSlugs = filter(links, filterRoutes);
				const routes = matchingSlugs
					.map((link) => {
						const route = link.slug.replace(/\/-/g, '/:');
						return { ...link, route };
					})
					.sort(({ route: routea }, { route: routeb }) => {
						const a = String(routea).split('/').length + !routea.includes('/:');
						const b = String(routeb).split('/').length + !routeb.includes('/:');
						return -1 * (a - b);
					});

				const routesMap = routes.reduce(
					(obj, { slug, route }) => Object.assign(obj, { [route]: slug }),
					{}
				);
				return (
					<StoryblokRoutesCtx.Provider value={{ links, routes, routesMap, reload }}>
						<Switch>
							{routes.map(({ slug, route }) => (
								<Route path={`/${route}`} component={StoryblokRoute} />
							))}
							<Route component={StoryblokRoute} />
						</Switch>
					</StoryblokRoutesCtx.Provider>
				);
			}}
		</Loader>
	);
}

export default StoryblokRoutes;

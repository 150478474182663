export default function stringifyCircular(data, tab) {
	const cache = new Map();
	const str = JSON.stringify(
		data,
		function (key, value) {
			if (typeof value === 'object' && value !== null) {
				// Duplicate reference found, discard key
				if (cache.has(value)) return;
				// Store value in our collection
				cache.set(value);
			}
			return value;
		},
		tab
	);

	return str;
}

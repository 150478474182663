import React, { createContext } from 'react';
import get from 'lodash/get';
import Story from './Story';

export const PageContext = createContext();
function Page(props) {
	const hasLayout = get(props, 'layout.cached_url');
	if (!hasLayout) {
		return (
			<PageContext.Provider value={props}>
				<Story story={{ linktype: 'story', cached_url: 'layouts/default' }} />
			</PageContext.Provider>
		);
	}

	return (
		<PageContext.Provider value={props}>
			<Story story={props.layout} />
		</PageContext.Provider>
	);
}

// const resolver = (prevProps, nextProps) =>
// 	get(prevProps, 'layout.cached_url') === get(nextProps, 'layout.cached_url');
// export default React.memo(Page, resolver);
export default Page;

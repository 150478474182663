import React, { useContext } from 'react';
import ComponentOutlet from '../components/ComponentOutlet';
import { PageContext } from './Page';

function LayoutContent() {
	const page = useContext(PageContext);
	if (!page) return null;

	return <ComponentOutlet components={page.body} />;
}
export default LayoutContent;

import React from 'react';
import concat from 'lodash/concat';
import BsNav from 'react-bootstrap/Nav';
import ComponentOutlet from '../components/ComponentOutlet';

function convertLinks(cmp) {
	if (cmp.component === 'link') {
		cmp.as = BsNav.Link;
		cmp.wrap = Nav.Item;
		cmp.activeClassName = 'active';
	}
	return cmp;
}

function Nav({ body, state, ...props }) {
	const stateProps = concat(state).reduce((o, k) => ({ ...o, [k]: true }), {});
	const cmps = concat(body).map(convertLinks);

	return (
		<BsNav {...stateProps} {...props}>
			<ComponentOutlet components={cmps} />
		</BsNav>
	);
}
Nav.defaultProps = {
	body: [],
};
export default Nav;
